import React, { useEffect, useState } from "react";
import styles from "./bottomNav.module.css";
import { Link } from "react-router-dom";
import logoDark from "./logo_dark.png";
import { IoIosSearch } from "react-icons/io";
import { BsCart2 } from "react-icons/bs";
import { IoMdMenu } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import axios from "axios";

////////// Left To Do //////////
//// * Change the Links Titles to ones from the given FakeAPI: https://fakestoreapi.com
//// * Add Links Paths to ones from the given FakeAPI: https://fakestoreapi.com
//// * Search Functionality
//// * Cart Path
//// 1. Add the Images below the Products lg Sub Menu & the Image next to the Shop lg Sub Menu
//// 2. Add the Cart Quantity number overlayed circle
//// 3. Menu and Sub Menu Animations
//// 4. Fix the OnMouseLeave Sub Menu bug (when approaching the link from below a lag occurs)
//// 5. Add the Keys for the Maps
//// 6. Overflow Issue on the right when opening the menu on the phone/tablet size
//// # Understand the HandleMediaQueryChange Function (+openMenu, +isDesktop)
//// # Automaticaally detect if the subMenu is normal or lg (array or dictionary). Also if it's large will need to be created outside of the <Li> but if it's normal it should be created inside the <Li>

const BottomNav = () => {
  const [toggle, setToggle] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 992);
  const [menusSub, setMenuSub] = useState({
    home: [
      "fashion 1",
      "fashion 2",
      "furniture 1",
      "furniture 2",
      "electronics 1",
      "electronics 2",
    ],
    pages: [
      "About Us",
      "Contact Us",
      "Faq",
      "404 Error Page",
      "Login",
      "Register",
      "Terms And Conditions",
    ],
    products: {
      Womans: [
        "Vestibulum sed",
        "Donec porttitor",
        "Donec vitae facilisis",
        "Curabitur tempus",
        "Vivamus in tortor",
      ],
      Mens: [
        "Donec vitae ante ante",
        "Etiam ac rutrum",
        "Quisque condimentum",
        "Curabitur laoreet",
        "Vivamus in tortor",
      ],
      Kids: [
        "Donec vitae facilisis",
        "Quisque condimentum",
        "Etiam ac rutrum",
        "Donec vitae ante ante",
        "Donec porttitor",
      ],
      Accessories: [
        "Donec vitae facilisis",
        "Quisque condimentum",
        "Etiam ac rutrum",
        "Donec vitae ante ante",
        "Donec porttitor",
      ],
    },
    blog: ["Grids", "Masonry", "Single Post", "List"],
    shop: {
      "Shop Page Layout": [
        "shop List view",
        "shop List Left Sidebar",
        "shop List Right Sidebar",
        "Left Sidebar",
        "Right Sidebar",
        "Shop Load More",
      ],
      "Other Pages": [
        "Cart",
        "Checkout",
        "My Account",
        "Wishlist",
        "compare",
        "Order Completed",
      ],
      "Product Pages": [
        "Default",
        "Left Sidebar",
        "Right Sidebar",
        "Thumbnails Left",
      ],
    },
  });

  const handleMenuClick = (menu) => {
    if (openMenu === menu) {
      // Close the menu if it's already open
      setOpenMenu(null);
    } else {
      // Open the selected menu and close others
      setOpenMenu(menu);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 992px)");

    // Function to handle screen size changes
    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setToggle(true);
        setIsDesktop(true);
      } else {
        setToggle(false);
        setIsDesktop(false);
      }
    };

    // Set the initial state based on the current screen size
    if (mediaQuery.matches) {
      setToggle(true);
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }

    // Add a listener for screen size changes
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Clean up the listener when the component is unmounted
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  useEffect(() => {
    axios
      .get("https://fakestoreapi.com/products")
      .then((resp) => {
        const products = resp.data;

        // Process the products data
        const sortedProducts = products.reduce((acc, product) => {
          const { category, title } = product;

          // If the category doesn't exist in the accumulator, create it
          if (!acc[category]) {
            acc[category] = [];
          }

          // Push the title into the appropriate category array
          acc[category].push(title);

          return acc;
        }, {});

        const sortedShop = products.reduce((accumulator, currentProduct) => {
          const { category, description } = currentProduct;
          if (!accumulator[category]) {
            accumulator[category] = [];
          }
          accumulator[category].push(description);
          return accumulator;
        }, {});

        // Update the state with the sorted products
        setMenuSub({ ...menusSub, products: sortedProducts });
      })
      .catch((er) => {
        console.log(er);
      });
  }, [menusSub]);

  return (
    <div className={styles.container}>
      <div className={styles.nav_container}>
        {/* <div className={styles.logo_container}> */}
        <Link to={"/"}>
          <img src={logoDark} alt="Logo" />
        </Link>
        {/* </div> */}

        <div className={styles.nav_right_container}>
          <ul
            className={styles.nav_links_container}
            style={{ display: toggle ? "flex" : "none" }}
          >
            <li onMouseLeave={isDesktop ? () => handleMenuClick(null) : null}>
              <Link
                className={styles.nav_link}
                onClick={!isDesktop ? () => handleMenuClick("home") : null}
                onMouseEnter={isDesktop ? () => handleMenuClick("home") : null}
              >
                <p className={styles.nav_link_title}>Home</p>
                <p className={styles.nav_link_drop_icon}>
                  <IoIosArrowDown />
                </p>
              </Link>

              {openMenu === "home" && (
                <div className={styles.nav_subMenu_container}>
                  {menusSub["home"].map((e) => (
                    <Link>
                      <p>{e}</p>
                    </Link>
                  ))}
                </div>
              )}
            </li>

            <li onMouseLeave={isDesktop ? () => handleMenuClick(null) : null}>
              <Link
                className={styles.nav_link}
                onClick={!isDesktop ? () => handleMenuClick("pages") : null}
                onMouseEnter={isDesktop ? () => handleMenuClick("pages") : null}
              >
                <p className={styles.nav_link_title}>Pages</p>
                <p className={styles.nav_link_drop_icon}>
                  <IoIosArrowDown />
                </p>
              </Link>
              {openMenu === "pages" && (
                <div className={styles.nav_subMenu_container}>
                  {menusSub["blog"].map((e) => (
                    <Link>
                      <p>{e}</p>
                    </Link>
                  ))}
                </div>
              )}
            </li>
            <div
              className={styles.lg_nav_link_container}
              onMouseLeave={isDesktop ? () => handleMenuClick(null) : null}
              style={{ width: isDesktop ? "100%" : "100%" }}
            >
              <li>
                <Link
                  className={styles.nav_link}
                  onClick={
                    !isDesktop ? () => handleMenuClick("products") : null
                  }
                  onMouseEnter={
                    isDesktop ? () => handleMenuClick("products") : null
                  }
                >
                  <p className={styles.nav_link_title}>Products</p>
                  <p className={styles.nav_link_drop_icon}>
                    <IoIosArrowDown />
                  </p>
                </Link>
              </li>
              {openMenu === "products" && (
                <div className={styles.nav_lg_subMenu_container}>
                  {Object.keys(menusSub["products"]).map((e) => (
                    <div>
                      <p className={styles.nav_lg_subMenu_title}>{e}</p>
                      {menusSub["products"][e].map((j) => (
                        <Link>
                          <p>{j}</p>
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <li onMouseLeave={isDesktop ? () => handleMenuClick(null) : null}>
              <Link
                className={styles.nav_link}
                onClick={!isDesktop ? () => handleMenuClick("blog") : null}
                onMouseEnter={isDesktop ? () => handleMenuClick("blog") : null}
              >
                <p className={styles.nav_link_title}>Blog</p>
                <p className={styles.nav_link_drop_icon}>
                  <IoIosArrowDown />
                </p>
              </Link>

              {openMenu === "blog" && (
                <div className={styles.nav_subMenu_container}>
                  {menusSub["blog"].map((e) => (
                    <Link>
                      <p>{e}</p>
                    </Link>
                  ))}
                </div>
              )}
            </li>

            <div
              className={styles.lg_nav_link_container}
              onMouseLeave={isDesktop ? () => handleMenuClick(null) : null}
              style={{ width: isDesktop ? "inherit" : "100%" }}
            >
              <li>
                <Link
                  className={styles.nav_link}
                  onClick={!isDesktop ? () => handleMenuClick("shop") : null}
                  onMouseEnter={
                    isDesktop ? () => handleMenuClick("shop") : null
                  }
                >
                  <p className={styles.nav_link_title}>Shop</p>
                  <p className={styles.nav_link_drop_icon}>
                    <IoIosArrowDown />
                  </p>
                </Link>
              </li>
              {openMenu === "shop" && (
                <div className={styles.nav_lg_subMenu_container}>
                  {Object.keys(menusSub["shop"]).map((e) => (
                    <div>
                      <p className={styles.nav_lg_subMenu_title}>{e}</p>
                      {menusSub["shop"][e].map((j) => (
                        <Link>
                          <p>{j}</p>
                        </Link>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </div>
            <li>
              <Link className={styles.nav_link}>
                <p className={styles.nav_link_title}>Contact Us</p>
              </Link>
            </li>
          </ul>

          <div className={styles.nav_icons_container}>
            <p>
              <IoIosSearch />
            </p>
            <p>
              <BsCart2 />
            </p>
            <div
              className={styles.nav_toggle_icon_container}
              onClick={() => {
                setToggle(!toggle);
                console.log(toggle);
              }}
            >
              <p className={styles.nav_toggle_icon}>
                <IoMdMenu />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomNav;
