import React from "react";
import UpperNav from "../../components/nav/upperNav/UpperNav";
import BottomNav from "../../components/nav/bottomNav/BottomNav";
import Header from "../../components/header/Header";

const Home = () => {
  return (
    <div>
      <Header />
    </div>
  );
};

export default Home;
