import "./App.css";
import BottomNav from "./components/nav/bottomNav/BottomNav";
import UpperNav from "./components/nav/upperNav/UpperNav";
import Home from "./pages/home/Home";

function App() {
  return (
    <div className="App">
      <UpperNav />
      <BottomNav />
      <Home />
    </div>
  );
}

export default App;
